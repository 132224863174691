<template>
    <div class="page">
      <ProposalForm
      :isEdit="true"
      :dateSource="proposalInfo"
      :isCouncil="isCouncil"
      :tokenInfo="tokenInfo"
      :tokenAuth="tokenAuth"
      :loadingApprove="loadingApprove"
      :loadingAdd="loadingAdd"
      @approveToken="approveToken"
      @addProposal="handleAdd"/>
    </div>
</template>

<script>
import ProposalForm from './components/ProposalForm.vue'
import daoObj from '../../contracts/contractDao'
import tokenObj from '../../contracts/contractToken'
import config from '../../config/app.config'
import { mapState } from 'vuex'

export default {
  name: '',
  components: { ProposalForm },
  data () {
    return {
      point: 0.000001,
      daoAt: '',
      isCouncil: false,
      proposalInfo: {},
      tokenInfo: {
        name: '-',
        totalSupply: 0,
        tokenAddress: ''
      },
      tokenAuth: false,
      loadingApprove: false,
      loadingAdd: false
    }
  },
  computed: {
    ...mapState(['proposalDetail'])
  },
  methods: {
    init () {
      this.getInfo()
    },
    async getInfo () {
      console.log('proposalDetail', this.proposalDetail)
      // const info = sessionStorage.getItem('proposalDetail') || ''
      if (!this.proposalDetail) this.$router.replace('/detail')
      this.proposalInfo = this.proposalDetail
      this.daoAt = this.proposalInfo.daoAt
      this.proposalId = this.proposalInfo.index
      this.tokenInfo.tokenAddress = this.proposalInfo.token
      this.getIsCouncil()
      this.getTokenInfo()
    },
    async getIsCouncil () {
      const resp = await daoObj.councliAt(this.daoAt)
      if (!resp.success) return
      if (!resp.result) this.isCouncil = false
      else this.isCouncil = true
    },
    async getTokenInfo () {
      this.getSymbol()
      await this.getTotalSupply()
      this.getTokenAllance()
    },
    async getSymbol () {
      const resp = await tokenObj.getSymbol(this.tokenInfo.tokenAddress)
      if (!resp.success) return
      this.tokenInfo.name = resp.result
    },
    async getTotalSupply () {
      const resp = await tokenObj.totalSupply(this.tokenInfo.tokenAddress)
      if (!resp.success) return
      this.tokenInfo.totalSupply = resp.result
    },
    async getTokenAllance () {
      const resp = await tokenObj.allowance(this.tokenInfo.tokenAddress, config.daoAddress)
      if (!resp.success) return
      const allowanceAmount = resp.result
      this.tokenAuth = allowanceAmount >= (this.tokenInfo.totalSupply * this.point)
    },
    async approveToken () {
      this.loadingApprove = true
      const resp = await tokenObj.approve(this.tokenInfo.tokenAddress, config.daoAddress)
      this.loadingApprove = false
      if (!resp.success) return
      this.tokenAuth = true
    },
    async handleAdd (params1, params2, mutilOption, options) {
      this.loadingAdd = true
      const resp = await daoObj.proposalEdit(this.daoAt, this.proposalId, params1, params2, mutilOption, options)
      this.loadingAdd = false
      console.log(resp)
      if (!resp.success) return
      this.$toast('success')
      this.$gbUtils.handleToPage('/detail')
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    // sessionStorage.removeItem('proposalDetail')
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 0 15px 20px 15px;
}
</style>
